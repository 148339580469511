<template>
  <div>
    <el-affiex>
      <nav-bar></nav-bar>
    </el-affiex>
    <tool-box></tool-box>
    <div class="container card my-1">
      <h3>{{ content.title ? content.title : '' }}</h3>
      <p v-html="content.content"></p>
    </div>
    <web-footer></web-footer>
  </div>
</template>

<script type="text/javascript">
import NavBar from '@/components/page/NavBar'
import WebFooter from '@/components/page/WebFooter'
import ToolBox from '@/components/toolbox'
import { getLocalStorage,setLocalStorage } from '@/utils/auth'
import {getSiteInfo,getAboutOrContactUs} from '@/api/about'

export default {
  data() {
    return {
      siteInfo:'',
      content:'',
      type:1, //1：关于我们，2：联系我们
    }
  },
  components: {
    NavBar,
    WebFooter,
    ToolBox,
  },
  props:{
    
  },
  created(){
    this.type = this.$route.params.type || 1
    this.findSiteInfo()
    this.findContent()
  },
  methods:{
    findSiteInfo(){
      if(!getLocalStorage('siteInfo')){
        getSiteInfo().then(
          res => {
            if(res.code == 1){
              this.siteInfo = res.data
              setLocalStorage('siteInfo',res.data)
            }
          }
        )
      }else{
        this.siteInfo = getLocalStorage('siteInfo')
      }
    },
    findContent(){
      getAboutOrContactUs({
        type:this.type
      }).then(res=>{
        if(res.code == 1){
          this.content = res.data
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.container{
  width:1200px;
  margin:20px auto;
}
.card{
  background-color:white;
  padding:20px;
  h3{
    height:60px;
    line-height: 60px;
    text-align: center;
  }
}
</style>